/* DOM element styling */
body,
html {
  /*
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ),
    url("../static/AbstractWhiteBG2-1200w.png");
  */
  background-image: url("../static/AbstractBG-v2-1280.png");
  background-size: cover;
  background-repeat: repeat-y;
  background-position: top;
  background-color: #f7f7f7;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
