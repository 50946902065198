/* DOM element styling */
a {
  color: #805e73;
}

h2 {
  text-align: center;
}

p {
    text-align: center;
}

.gcotg-button, .login-modal-button {
  background-color: #805e73;
  border-color: transparent;
}

.gcotg-button:disabled, .login-modal-button:disabled {
  background-color: #7e7179;
  border-color: transparent;
}

.gcotg-button:hover, .login-modal-button:hover {
  background-color: #6a4e5f;
  border-color: transparent;
  transform: scale(1.1);
  transition: transform 0.5s;
}

/*.login-modal-button:active,
.login-modal-button:focus,
.login-modal-button:focus-visible,
.gcotg-button:active,
.gcotg-button:focus,
.gcotg-button:focus-visible {
  background-color: #594e6a !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}*/

.gcotg-button-secondary {
  background-color: #084C61;
  border-color: transparent;
  margin: 10px;
}

.gcotg-button-secondary:hover {
  background-color: #063B4B;
  border-color: transparent;
  transform: scale(1.1);
  transition: transform 0.5s;
}

.upload-button {
  text-align: left;
  display: block;
}

/* Utility classes for overriding styling */
.no-padding {
  padding: 0 !important;
}

.gcotg-error-message {
  color: #BF6900 !important;
  margin-top: 5px;
  text-align: center;
}

/* Element specific classes */
.gcotg-top-level-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.gcotg-login-top-level {
  border-radius: 4px;
  /*
  position: relative;
  display: flex;
  flex-direction: column;
  */
  border: 1px #dddddd solid;
  background-color: #fff;
  max-width: 1200px;
  min-width: 300px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10%;
  margin-right: 10%;
}

.login-content-box {
  padding: 20px 10%;
}

.main-logo {
  width: 75%;
  height: auto;
  max-width: 200px;
  display: block;
  margin: 25px auto;
}

.gcotg-form {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.gcotg-form-group {
  margin-bottom: 20px;
}

.gcotg-form-label {
  margin-bottom: 0;
  display: block; /* Overrides default inline style of label */
}

.gcotg-form-element {
  margin-top: 1px !important;
  margin-bottom: 20px;
}

.login-zipcode {
  font-weight: bold;
  font-size: large;
}


.login-modal-header, .admin-modal-header, .claim-status-modal-header {
  font-weight: bold;
  font-size: x-large;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.login-modal-body, .admin-modal-body {
  margin: 20px;
  text-align: left;
}

.admin-modal-body p {
  text-align: left;
}

.admin-modal-footer {
  justify-content: space-between;
}

.admin-offer-expired {
  background-color: #adadad !important;
  color: white !important;
}

.login-modal-body p {
  text-align: left;
}

.login-modal-intro-text {
  text-align: left;
}


.navbar-logo {
  width: 100px;
  height: auto;
  display: block;
}

.gcotg-user-navbar {
  background-color: white;
  padding: 5px 25px;
  border-bottom: 1px solid #dddddd;
}

.gcotg-navbar-link {
  color: #805e73;
}

.gcotg-navbar-link:hover {
  color: #6A4E5F;
}

.gcotg-footer {
  background-color: white;
  padding: 20px;
  text-align: center;
  margin-top: auto;
  border-top: 1px solid #dddddd;
  width: 100%;
  height: 65px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}

.gcotg-spinner {
  margin-bottom: 20px;
}

.gcotg-userhome-top-level {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.gcotg-user-home-content {
  flex: 1 0 auto;
  min-height: calc(100% - 65px);
}

.userhome-main-content-col {
  display: block;
  position: relative;
  text-align: center;
  max-width: 1200px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.userhome-greeting {
  margin-top: 50px;
  margin-bottom: 10px;
}

.no-offers-message {
  margin-top: 50px;
  font-size: large;
}

.new-offers-outer-div {
  border: 2px solid #805e73;
  border-radius: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.new-offers-div {
  margin-bottom: 50px;
}

.new-offers-message {
  margin-bottom: 20px;
}

.new-offers-item {
  margin: 10px 10px;
}

.gcotg-table {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.gcotg-claimed-gc-container {
  padding-top: 10px;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: -20px;
  margin-bottom: 75px;
}

.pending-table .pending-table-header {
  background-color: #6a4e5f;
  color: white;
  padding: 10px;
  border-radius: 3px;
  margin-top: 50px;
}

.pending-offers-message {
  color: white;
  font-size: small;
  font-style: italic;
}

.claimed-gc-header {
  background-color: #6a4e5f;
  color: white;
  padding: 10px;
  border-radius: 3px;
  margin-top: 50px;
}

.failed-table .failed-table-header {
  background-color: #6a4e5f;
  color: white;
  padding: 10px;
  border-radius: 3px;
  margin-top: 50px;
}

.failed-offers-message, .failed-table-header a {
  color: white;
  font-size: small;
  font-style: italic;
}

.gcotg-info-message {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.gc-fine-print {
  font-size: x-small;
  display: block;
  margin-top: 10px;
}

.gc-fine-print-line {
  display: block;
  line-height: 1.5;
}

.card-column {
  margin-bottom: 20px;
}

.backoffice-login-img {
  display: block;
  width: 150px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.backoffice-login-card-container {
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px
}

.backoffice-title {
  margin-bottom: 20px;
}

.backoffice-screen-card-container {
  padding: 25px;
  min-width: 800px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.backoffice-form-label {
  text-align: left;
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}

.backoffice-fileload-form-group {
  text-align: left;
  margin-bottom: 20px;
}

.backoffice-pulldown-list {
  min-height: 35px;
  min-width: 300px;
}

.backoffice-toast-container {
  text-align: left;
  --toastify-toast-width: 450px;
}

.backoffice-tab-container {
  padding: 20px;
  text-align: center;
}

.back-office-tabs .nav-link {
  color: #805E73;
}

.failed-offers-table {
  font-size: small;
}

.offer-retry-form,
.cancel-retry-form {
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.frys-text {
  max-width: 800px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  display: none;
}

.show-test-offers-checkbox {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 5px;
  padding: 10px;
}

.admin-screen-divider-div {
  border-style: solid;
  border-color: #e5e5e5;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.load-row-inactive {
  background-color: #f8d7da !important;
  color: #58151c !important;
  border-color: #f1aeb5 !important;
}

[id^="custom-switch"]:checked, .load-active-switch:checked, #custom-switch:checked {
  background-color: #805e73 !important;
  border-color: #805e73 !important;
}

.switch-changed {
  background-color: #f8d7da !important;
  color: #58151c !important;
  border-color: #f1aeb5 !important;
}

.load-activation-warning {
  font-size: small;
  margin-top: 10px;
}

.player-id-search-input {
  max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.player-offer-table, .brand-catalog-table, .problem-offers-table {
  font-size: small;
  vertical-align: middle;
}

.player-offer-list-div, .brand-catalog-list-div {
    margin-top: 50px;
}

.gcotg-pagination {
  justify-content: end;
}

.pagination-description {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px;
  font-size: small;
}

.login-screen-title {
  margin-top: 50px;
}

.login-screen-message {
  max-width: 600px;
  font-size: medium;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  font-style: italic;
}

.player-profile-details {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.player-profile-details-header {
  margin-bottom: 20px;
}

.reactivate-button-span {
    margin-left: 10px;
}

.player-disabled-message {
  color: #805E73;
}

.new-claim-label {
  font-weight: bold;
  font-size: medium;
  background-color: #805E73;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 5px;
  border-radius: 50px;
}

a.nounderline {
  text-decoration: none;
  color: black;
}

.click-here-gc-text {
  font-size: medium;
  font-weight: bold;
  text-decoration: underline;
  color: #805E73;
}

.successful-claim-text {
  margin-left: auto;
  margin-right: auto;
}

.sigmar-center-element {
    margin-left: auto;
    margin-right: auto;
}

.gcotg-claimed-gc-fine-print {
  font-size: small;
  font-style: italic;
  display: block;
  max-width: 600px;
  margin: 10px auto;
}

.edit-in-place-form-field {
  margin: 10px;
}

.in-place-form-button-primary {
  background-color: #6a4e5f;
  border-color: transparent;
  margin-left: 10px;
  margin-right: 10px;
}

.in-place-form-button-primary:hover {
  background-color: #594e6a;
  border-color: transparent;
  transform: scale(1.1);
  transition: transform 0.5s;
}

.in-place-form-button-secondary {
  background-color: #084C61;
  border-color: transparent;
  margin-left: 10px;
  margin-right: 10px;
}

.in-place-form-button-secondary:hover {
  background-color: #063B4B;
  border-color: transparent;
  transform: scale(1.1);
  transition: transform 0.5s;
}
